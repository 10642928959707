@import "../../Styles/Utils/functions.scss";

.section {
    composes: section from '../../Components/PaymentConfirmationPanel/payment-confirmation-panel.module.scss';
}

.cost {
    text-align: center;
    margin: 16px 0 0;
    font-size: em(32);
    font-weight: bold;
}