@import '../../Styles/Utils/breakpoints';
@import '../../Styles/Utils/functions';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.2);

    h1 {
        margin: 0 0 4px;
        font-size: em(22);
    }
    p {
        margin: 0;
        opacity: 0.75;
    }

    @include br-medium-tablet {
        h1 {
            font-size: em(28);
        }
    }
}

.lightStyles {
    border: 1px solid rgba(255,255,255,.2);

    h1, p {
        color: #fff;
    }
}
