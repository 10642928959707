:root {
    --color-brand: #006bff;
    --color-background: #f7f7f8;

    --color-grey-x-light: #f7f7f8;
    --color-grey-light: #ececed;
    --color-grey-mid-light: #dadada;
    --color-grey-mid: #b6b6b6;
    --color-grey-mid-dark: #787878;

    --color-border-default: var(--color-grey-light);
    --color-border-mid-light: var(--color-grey-mid-light);
    --color-border-mid-dark: #d8d8d8;
    --color-border-dark: #b6b6b6;

    --color-input-background: #f7f7f8;

    --color-button-default: var(--color-grey-x-light);
    --color-button-icon: #3dace7;

    --color-negative-x-light: #f9e2e0;
    --color-negative-default: #e26c63;
    --color-negative-dark: #dd5146;

    --color-positive-x-light: #e6efdf;
    --color-positive-default: #90c46b;

    --color-warning-x-light: #fef7ed;
    --color-warning-default: #fcb13f;

    --color-cta-primary: #333;
    --color-cta-primary-dark: #222;
    --color-cta-default: #3cb78c;
    --color-cta-dark: #16987c;

    --color-text-light: #bbb;
    --color-text-mid: #787878;
    --color-text-dark: #333;
    --color-text-link: #1a81ad;
    --color-text-negative: #bc5c46;

    --color-schedule-session: #35abea;
}
