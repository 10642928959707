.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-height: 100%;
    text-align: center;
    position: relative;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.lightText {
        color: #fff;
    }
}

.poweredBy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 16px 0;
    text-align: center;
    font-size: .8rem;

    a {
        opacity: .6;
        color: var(--color-text-dark);
    }
}

.darkBrand a {
    opacity: .75;
    color: #fff;
}