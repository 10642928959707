$size-large-phone: 400px;
$size-tiny-tablet: 480px;
$size-small-tablet: 540px;
$size-medium-tablet: 600px;
$size-large-tablet: 720px;
$size-small-desktop: 1024px;

@mixin br-large-phone {
    @media (min-width: #{$size-large-phone}) {
        @content;
    }
}

@mixin br-tiny-tablet {
    @media (min-width: #{$size-tiny-tablet}) {
        @content;
    }
}

@mixin br-small-tablet {
    @media (min-width: #{$size-small-tablet}) {
        @content;
    }
}

@mixin br-medium-tablet {
    @media (min-width: #{$size-medium-tablet}) {
        @content;
    }
}

@mixin br-large-tablet {
    @media (min-width: #{$size-large-tablet}) {
        @content;
    }
}

@mixin br-small-desktop {
    @media (min-width: #{$size-small-desktop}) {
        @content;
    }
}