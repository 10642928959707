@import "../../Styles/Utils/breakpoints";

.container {
    width: 70px;
    height: 70px;
    margin-bottom: 8px;
    border-radius: 100%;
    border: 5px solid rgba(0,0,0,.2);

    @include br-medium-tablet {
        width: 100px;
        height: 100px;
    }
}
.lightBorder {
    border: 5px solid rgba(255,255,255,.2);
}