.container {
    display: block;
    padding-left: 36px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    span {
        display: inline-block;
        position: relative;
        min-height: 30px;
        padding-top: 3px;
        cursor: pointer;

        &:before {
            transition: border 200ms;
            content: "";
            position: absolute;
            top: 0;
            left: -36px;
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: #fff;
            border: 1px solid var(--color-border-mid-light);
            border-radius: 4px;
        }
    }

    input:checked ~ span:after {
        background: #333;
        //box-shadow: inset 0 0 0 6px var(--color-input-background);
        content: "";
        position: absolute;
        top: 7px;
        left: -29px;
        width: 16px;
        height: 16px;
        border-radius: 2px;
    }
}