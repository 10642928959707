@import "../../Styles/Utils/breakpoints.scss";

.section {
    padding: 20px;
    border-bottom: 1px solid var(--color-border-default);

    @include br-medium-tablet {
        padding: 32px;
    }
}
.footer {
    padding: 20px;
    text-align: left;

    @include br-medium-tablet {
        padding: 32px;
    }
}
.customer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 100%;
        background: var(--color-positive-default);

        svg {
            fill: #fff;
            width: 16px;
            height: 16px;
        }
    }
    svg {
    }
    > p {
        margin: 0;
        font-weight: bold;
        line-height: 1;
    }
}