.container {
    display: block;
    padding-left: 36px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    span {
        display: inline-block;
        position: relative;
        min-height: 30px;
        padding-top: 3px;
        cursor: pointer;

        &:before {
            transition: border 200ms;
            content: "";
            position: absolute;
            top: 0;
            left: -36px;
            display: inline-block;
            width: 30px;
            height: 30px;
            border: 1px solid var(--color-border-default);
            background: var(--color-input-background);
            border-radius: 100%;
        }
    }

    input:checked ~ span:before {
        background: #333;
        box-shadow: inset 0 0 0 6px var(--color-input-background);
    }
}