@import "../../Styles/Utils/functions.scss";

.description {
    composes: section from '../../Components/PaymentConfirmationPanel/payment-confirmation-panel.module.scss';
    border-bottom: 1px solid var(--color-border-default);
    text-align: center;
    font-size: em(16);
    font-weight: bold;
    white-space: pre-wrap;
}
.cost {
    margin: 16px 0 0;
    font-size: em(32);
}
.terms {
    composes: section from '../../Components/PaymentConfirmationPanel/payment-confirmation-panel.module.scss';
    max-height: 200px;
    overflow: auto;
    white-space: pre-wrap;
}