@import "../Utils/functions";

body,
button {
    letter-spacing: -0.02em!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "soleil", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: em(15);
    line-height: 1.4;
}

h1 {
    line-height: 1.2;
}

p {
    margin: 0 0 16px;
}

a {
    color: var(--color-text-link);
    cursor: pointer;
    text-decoration: none;
}