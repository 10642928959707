.container {

}

.method {
    margin-top: 12px;

    label {
        text-transform: capitalize;
    }
}

.element {
    composes: input from '../TextInputField/text-input-field.module.scss';
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    > * {
        flex: 1;
    }
}
