@import "../../Styles/Utils/breakpoints";

.container {
    display: grid;
    grid-gap: 12px;
    position: relative;
    max-width: 860px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 20px;

    &.hasBackButton {
        padding-top: 80px;
    }

    > div {
        max-width: 600px;
        margin: 0 auto;
    }

    @include br-medium-tablet {
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr;
    }
}

.portalButton {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 160px;
    height: auto;
    margin-left: -80px;
    padding: 6px;
}