.container {

}

.check {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin: 0 auto 16px;
    background: rgba(0,0,0,.1);
    border-radius: 100%;

    svg {
        width: 32px;
        height: 32px;
        fill: rgba(0,0,0,.6);
    }

    &.light {
        background: rgba(255,255,255,.1);

        svg {
            fill: rgba(255,255,255,.6);
        }
    }
}