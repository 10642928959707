.input {
    appearance: none;
    transition: border-color 200ms;
    width: 100%;
    height: 48px;
    padding: 8px 10px 10px;
    background: #fff;
    border: 1px solid var(--color-border-default);
    background: var(--color-input-background);
    border-radius: 4px;
    outline: none;
    color: var(--color-text-dark);
    font-size: 1rem;
    line-height: 1.5rem;

    &::placeholder {
        color: var(--color-text-light);
    }

    &:hover {
        border-color: var(--color-border-mid-dark);
    }
}