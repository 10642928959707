.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    text-align: center;
    font-weight: normal;

    &.positive {
        background: var(--color-positive-x-light);

        .icon {
            background: var(--color-positive-default);
        }
    }
    &.warning {
        background: var(--color-warning-x-light);

        .icon {
            background: var(--color-warning-default);
            padding-bottom: 2px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
    &.negative {
        background: var(--color-negative-x-light);

        .icon {
            background: var(--color-negative-default);
            padding-bottom: 2px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-bottom: 8px;
    border-radius: 100%;

    svg {
        fill: #fff;
    }
}
