@import "../../Styles/Utils/functions";

.container {
    display: flex;
}

.prepend {
    flex: 0 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 38px;
    padding: 0 8px 2px;
    background: #f6f6f6;
    border: 1px solid var(--color-border-default);
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: em(18);
    font-weight: bold;
    text-align: center;
    color: var(--color-text-light);

    ~ input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
