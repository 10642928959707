@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.container {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 auto;

    &.sizeLarge {
        height: 32px;
        width: 32px;
    }
}

.spinner {
    animation: rotate .6s linear infinite;
    position: absolute;
    top: calc(50% - (16px / 2));
    left: calc(50% - (16px / 2));
    z-index: 110;
    height: 16px;
    width: 16px;
    border: 2px solid #a1a1a1;
    border-radius: 100%;
    border-right-color: transparent;
    border-top-color: transparent;

    &.variantLight {
        border-top-color: rgba(255,255,255,.2);
        border-right-color: rgba(255,255,255,.2);
        border-bottom-color: rgba(255,255,255,.6);
        border-left-color: rgba(255,255,255,.6);
    }

    &.sizeLarge {
        top: calc(50% - (32px / 2));
        left: calc(50% - (32px / 2));
        height: 32px;
        width: 32px;
    }
}