@import "../../Styles/Utils/functions.scss";

.container {
    margin-bottom: 20px;
}
.label {
    display: block;
    margin-bottom: 6px;
    color: var(--color-text-light);
    text-transform: uppercase;
    font-size: em(12);

    span.required {
        color: var(--color-text-negative);
    }
}
.error {
    margin: 4px 0 0;
    color: var(--color-text-negative);
}