.container {
    margin-bottom: 12px;

    &:last-child {
        margin: 0;
    }
}
.label {
    composes: label from '../FormField/form-field.module.scss';
}

.content {
    margin-bottom: 8px;
}
