@import url("https://use.typekit.net/vzb1oki.css");
@import "Styles/Base/normalize.scss";
@import "Styles/Base/colors.scss";
@import "Styles/Base/typography.scss";

*, ::before, ::after {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    background: var(--color-background);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
