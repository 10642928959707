@import "../../Styles/Utils/breakpoints.scss";

.section {
    composes: section from '../PaymentConfirmationPanel/payment-confirmation-panel.module.scss';
}
.fieldsSection {
    composes: section from '../PaymentConfirmationPanel/payment-confirmation-panel.module.scss';
    margin-bottom: -20px;
    padding-bottom: 0;
    border: none;
}