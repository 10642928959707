@import "../../Styles/Utils/breakpoints.scss";
@import "../../Styles/Utils/functions.scss";

.container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 600px;
    min-height: 100px;
    margin: 0 auto 10px;
    background: #fff;
    border-radius: 12px;
    color: var(--color-text-dark);
}

.header {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    background: #fff;
    border-bottom: 1px solid var(--color-border-default);
    border-radius: 4px 4px 0 0;
    z-index: 100;

    h3 {
        margin: 0;
        font-size: em(18);
        padding: 16px;
        font-weight: bold;
        color: var(--color-text-dark);
    }
}

.status {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #fff;
    border-bottom: 1px solid var(--color-border-default);
    text-align: left;

    @include br-large-tablet {
        padding: 16px 20px;
    }

    svg {
        min-width: 16px;
        margin-right: 12px;
    }

    &.success svg {
        fill: var(--color-positive-default);
    }
    &.warning svg {
        fill: var(--color-warning-default);
    }
    &.error {
        color: var(--color-text-negative);

        svg {
            fill: var(--color-negative-default);
        }
    }
}

.content {
    text-align: left;
}

.section {
    composes: section from '../PaymentConfirmationPanel/payment-confirmation-panel.module.scss';
    margin-bottom: -20px;
    padding-bottom: 0;
    border: none;
}
