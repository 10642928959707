@import "../../Styles/Utils/functions.scss";
@import "../../Styles/Utils/breakpoints.scss";

@keyframes rotate { 100% { transform:rotate(360deg); } }

.default {
    display: inline-flex;
    -webkit-appearance: none;
    font-size: em(16);
    line-height: 1.4;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    opacity: 1;
    transition: background .1s, color .1s, opacity .1s;
    justify-content: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    height: 48px;
    padding: 0 12px;
    color: var(--color-text-dark);
    background-color: var(--color-grey-x-light);
    border: 1px solid var(--color-border-default);
    border-radius: 6px;
    box-shadow: none;

    span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    svg {
        fill: var(--color-button-icon);
    }
}

// --------------------------------------------------------------------------------------------------

.isLoading {
    color: transparent!important;
    pointer-events: none;

    &:after {
        animation: rotate .6s linear infinite;
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - (1em / 2));
        left: calc(50% - (1em / 2));
        height: 1em;
        width: 1em;
        border: 2px solid #a1a1a1;
        border-radius: 100%;
        border-right-color: transparent;
        border-top-color: transparent;
    }
}

.isDisabled {
    opacity: .5;
}

.isFullWidth {
    width: 100%;
}

.isResponsive {
    width: 40px;

    span > span {
        display: none;
    }

    @include br-small-tablet {
        width: auto;

        span > span {
            display: block;
            margin-left: 6px;
        }
    }
}

// --------------------------------------------------------------------------------------------------

.sizeLarge {
    height: 60px;
    padding: 0 24px;
    font-size: em(16);
}

// --------------------------------------------------------------------------------------------------

.variantLight {
    background: rgba(255,255,255,.1);
    color: #fff;

    &:hover {
        background: rgba(255,255,255,.2);
    }
}

.variantDark {
    background: rgba(0,0,0,.1);
    color: var(--color-text-dark);

    &:hover {
        background: rgba(0,0,0,.2);
    }
}

.variantPrimary {
    background-color: var(--color-cta-primary);
    font-weight: 600;
    color: #fff;

    &:hover:enabled {
        background-color: var(--color-cta-primary-dark);
    }
    &:after {
        border-color: #fff;
        border-right-color: transparent;
        border-top-color: transparent;
    }
}

.variantSemiTransparent {
    background: rgba(0,0,0,.2);
    border-color: transparent;
    color: var(--color-text-dark);

    &:hover:enabled {
        background: rgba(0,0,0,.1);
    }

    &.lightBackground {
        color: #fff;
        background: rgba(255,255,255,.1);

        &:hover:enabled {
            background: rgba(255,255,255,.2);
        }
    }
}

.variantDestructive {
    background-color: var(--color-negative-default);
    color: #fff;

    &:hover:enabled {
        background-color: var(--color-negative-dark);
    }
    &:after {
        border-color: #fff;
        border-right-color: transparent;
        border-top-color: transparent;
    }
}

.variantText {
    background: transparent;
    color: var(--color-text-link);
}